// ============================================================================
// Contact Stylesheet
// ============================================================================

.left-border-wrap {
  border-right: 1px solid #CCC;
  padding-right: 15px;
  margin-right: -15px;
}

.right-border-wrap {
  border-left: 1px solid #CCC;
  padding-left: 15px;
  margin-left: -16px;
}