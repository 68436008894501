// ============================================================================
// Buttons
// ============================================================================

%scaffold {
  @extend .btn;
  white-space: normal !important;
  word-wrap: break-word;
  transition: all ease 500ms;
  &:hover, &:focus, &:active {
    outline: 0;
  }
}

.btn-theme-search {
  @extend %scaffold;
  margin-left: 10px !important;
  border-radius: 50% !important;
  color: #FFF;
  padding: 5px 7px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: $theme-primary;
  &:hover, &:active, &:focus {
    color: $theme-primary;
    border-color:#FFF;
    background-color: #000;
  }
}

.btn-theme-primary {
  @extend %scaffold;
  padding: 8px 15px;
  color: #FFF;
  background-color: $theme-primary;
  &:hover, &:active, &:focus {
    color: #FFF;
    background-color: #000;
  }
}