.carousel {
  &.carousel-fade {
    // Specificity Fix
    .carousel-inner {
      .item {
        transition-property: opacity;
      }
      .item,
      .active.left,
      .active.right {
        opacity: 0;
      }
      .active,
      .next.left,
      .prev.right {
        opacity: 1;
      }
      .next,
      .prev,
      .active.left,
      .active.right {
        left: 0;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .carousel-inner {
    .item {
      .carousel-caption {
        background-color: $theme-primary;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        span {
          text-shadow: none;
          font-size: 32px;
          line-height: 55px;
          font-weight: 700;
        }
        @media (min-width: $screen-sm-min) {
          position: absolute;
          top: 50%;
          left: 0;
          right: auto;
          bottom: auto;
          transform: translateY(-50%);
          background-color: $theme-primary-trans;
          span {
            font-size: 44px;
          }
        }
      }
    }
  }
  .carousel-indicators {
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 15;
    text-align: center;
    margin: 0;
    li {
      margin: 0 2px;
      width: 15px;
      height: 15px;
      border: 0;
      background-color: rgba(255, 255, 255, 0.4);
      &.active {
        background-color: #FFF;
      }
    }
  }
}