// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================

// ATTENTION: Button styles are now in 'editor.scss' due to the unfortunate repercussions of using @extend

// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

.lead {
  // For the style to register, it needs some kind of definition.
  // Just apply the default display value
  display: block;
}

* {
  word-wrap: break-word;
}

h1 {
  color: #000;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  @media (min-width: $screen-sm-min) {
    font-size: 44px;
    line-height: 55px;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

ol {
  li {
    margin-bottom: 5px;
    line-height: 24px;
  }
}

ul {
  li {
    &:not(.link):not(.current) {
      margin-bottom: 5px;
      line-height: 24px;
    }
  }
}

p {
  margin-bottom: 5px;
  line-height: 24px;
}

a {
  color: $theme-primary;
  transition: all ease 500ms;
  &:hover, &:focus, &:active {
    color: darken($theme-primary, 5%);
    text-decoration: none;
  }
}

hr {
  margin: 14px 0;
  border-top: 1px solid #CCC;
}

blockquote {

}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.no-break {
  white-space: nowrap;
}
