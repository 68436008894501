// ============================================================================
// Scaffolding
// ============================================================================

body {
  background-color: $theme-bg-primary;
  padding-top: $navbar-height;
  @media (min-width: $grid-float-breakpoint) {
    padding-top: $uncollapsed-nav-height;
    &.home-page {
      padding-top: 0;
    }
  }
}

.strong {
  font-weight: bold;
}

.texture {
  background-color: #FFF;
}

.gradient {
  background: $theme-bg-primary url('/themes/default/images/gradient.png') 0 0;
  .img-responsive {
    width: 100%;
  }
  .acsent {
    width: 100%;
    height: 8px;
    background-color: rgba(0, 0, 0, .1);
  }
}

.overlay {
  position: relative;
  &:before {
    content: '';
    transition: opacity ease 500ms;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
  }
  &:hover, &:active, &:focus {
    &:before {
      opacity: .3;
    }
  }
}

.img-centered {
  &.img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-restricted {
  &.img-responsive {
    width: 100%;
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
  .img-responsive {
    width: 100%;
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
}

.map {
  min-height: 400px;
}