// ============================================================================
// Home Page Layout
// ============================================================================

.gateway {
  color: #000;
  @media (min-width: $screen-sm-min) {
    min-height: 380px;
    margin: 0 -10px;
  }
  .title {
    background-color: $theme-primary;
    color: #FFF;
    h3 {
      margin: 0;
    }
  }
}