// ============================================================================
// Navbar
// ============================================================================

.navbar {
  // Collapsed Styles
  background-color: transparent; // Navbar background colour
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  .navbar-header {
    background-color: #FFF;
    button {
      transition: background-color ease 500ms;
      border: 0;
      border-radius: 0;
      background-color: $theme-primary;
      margin: 13px 15px;
      padding: 25px 20px;
      .icon-bar {
        background-color: #FFF;
      }
      &:hover, &:active, &:focus {
        background-color: #000;
      }
    }
    .navbar-brand {
      img {
        margin-top: -25px;
        max-width: 150px;
      }
    }
  }
  .navbar-collapse {
    padding: 0;
    @media (min-height: 630px) {
      max-height: none;
    }
  }
  .navbar-nav {
    margin: 0;
    li {
      a {
        color: #FFF; // Navbar link colour
        background-color: $theme-primary; // Navbar link background colour
        margin-top: 2px;
        &:hover, &:focus, &:active {
          color: #FFF; // Navbar link colour when hovered/focused/activated
          background-color: #000; // Navbar link background colour when hovered/focused/activated
        }
      }
      &.current, &.section {
        > a {
          color: #FFF; // Navbar link colour when current (SS)
          background-color: #000; // Navbar link background colour when current (SS)
          &:hover, &:focus, &:active {
            color: #FFF; // Navbar link colour when hovered/focused/activated and current (SS)
            background-color: #000; // Navbar link background colour when hovered/focused/activated and current (SS)
          }
        }
      }
    }
    .open {
      a {
        color: #FFF; // Navbar link colour when open
        background-color: #000; // Navbar link background colour when open
        &:hover, &:focus, &:active {
          color: #FFF; // Navbar link colour when open and hovered/focused/activated
          background-color: #000; // Navbar link background colour when open and hovered/focused/activated
        }
      }
      .dropdown-menu {
        padding: 0;
        background-color: $theme-primary; // Navbar dropdown background colour
        // border-color: ; // Navbar dropdown border colour
        li {
          a {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 2px solid #FFF;
            color: #FFF;
            background-color: $theme-primary; // Navbar dropdown link background colour
            // border-color: ; // Navbar dropdown link Navbar border colour
            &:hover, &:focus, &:active {
              color: #FFF; // Navbar dropdown link colour when hovered/focused/activated
              background-color: #000; // Navbar dropdown link background colour when hovered/focused/activated
            }
          }
          &.current, &.section {
            > a {
              // color: ; // Navbar dropdown link colour when current (SS)
              background-color: #000; // Navbar dropdown link background colour when current (SS)
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    // Expanded Styles
    background-color: #FFF; // Navbar background colour
    // border-color: ; // Navbar border colour
    min-height: $uncollapsed-nav-height;
    .navbar-header  {
      .navbar-brand {
        line-height: $uncollapsed-line-height;
        height: $uncollapsed-nav-height;
        padding-top: (($uncollapsed-nav-height - $uncollapsed-line-height) / 2);
        padding-bottom: (($uncollapsed-nav-height - $uncollapsed-line-height) / 2);
        img {
          margin-top: -((($uncollapsed-nav-height - $uncollapsed-line-height) / 2 ) - 10);
          max-width: 230px;
        }
      }
    }
    .navbar-nav {
      li {
        a {
          // color: ; // Navbar link colour
          // background-color: ; // Navbar link background colour
          margin-top: 0;
          margin-left: 1px;
          line-height: $uncollapsed-line-height;
          @if ($uncollapsed-link-padding > (($uncollapsed-nav-height - $uncollapsed-line-height) / 2)) {
            margin-top: 0;
            margin-bottom: 0;
          } @else {
            margin-top: ((($uncollapsed-nav-height - $uncollapsed-line-height) / 2) - $uncollapsed-link-padding);
            margin-bottom: ((($uncollapsed-nav-height - $uncollapsed-line-height) / 2) - $uncollapsed-link-padding);
          }
          @if ($uncollapsed-link-padding > (($uncollapsed-nav-height - $uncollapsed-line-height) / 2)) {
            padding-top: (($uncollapsed-nav-height - $uncollapsed-line-height) / 2);
            padding-bottom: (($uncollapsed-nav-height - $uncollapsed-line-height) / 2);
          } @else {
            padding-top: $uncollapsed-link-padding;
            padding-bottom: $uncollapsed-link-padding;
          }
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when hovered/focused/activated
            // background-color: ; // Navbar link background colour when hovered/focused/activated
          }
        }
        &.current, &.section {
           > a {
            // color: ; // Navbar link colour when current (SS)
            // background-color: ; // Navbar link background colour when current (SS)
            &:hover, &:focus, &:active {
              // color: ; // Navbar link colour when hovered/focused/activated and current (SS)
              // background-color: ; // Navbar link background colour when hovered/focused/activated and current (SS)
            }
          }
        }
      }
      .open {
        a {
          // color: ; // Navbar link colour when open
          // background-color: ; // Navbar link background colour when open
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when open and hovered/focused/activated
            // background-color: ; // Navbar link background colour when open and hovered/focused/activated
          }
        }
        .dropdown-menu {
          // background-color: ; // Navbar dropdown background colour
          // border-color: ; // Navbar dropdown border colour
          border: 0;
          border-radius: 0;
          @if ($uncollapsed-link-padding > (($uncollapsed-nav-height - $uncollapsed-line-height) / 2)) {
            margin-top: 0;
          } @else {
            margin-top: -(((($uncollapsed-nav-height - $uncollapsed-line-height) / 2) - $uncollapsed-link-padding) - 1);
          }
          li {
            a {
              border: 0;
              //background-color: ; // Navbar dropdown link background colour
              // border-color: ; // Navbar dropdown link Navbar border colour
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated
                //background-color: ; // Navbar dropdown link background colour when hovered/focused/activated
              }
            }
            &.current, &.section {
              > a {
                // color: ; // Navbar dropdown link colour when current (SS)
                // background-color: ; // Navbar dropdown link background colour when current (SS)
                &:hover, &:focus, &:active {
                  // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                  // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
                }
              }
            }
          }
          &.dropdown-form {
            padding: 5px 10px;
            border-radius: 0 0 0 15px;
          }
        }
      }
    }
  }
}