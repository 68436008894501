// ============================================================================
// Search
// ============================================================================

.inline-search {
  min-width: 280px;
  padding: 5px 10px;
  .input-group {
    .form-control {
      transition: background-color ease 500ms;
      border: 0;
      border-radius: 0;
      color: #FFF;
      background-color: rgba(255, 255, 255, 0.4);
      &:hover, &:focus, &:active {
        color: #333;
        background-color: #FFF;
      }
    }
  }
}

.block-search {

}

.full-search {
  .inline-search {
    .input-group {
      .form-control {
        color: #333;
      }
    }
  }
  .block-search {

  }
}