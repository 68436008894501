// ============================================================================
// Inner Page Layout
// ============================================================================

.inner-sidebar {
  .panel {
    border: 0;
    border-radius: 0;
    .panel-body {
      .list-group {
        margin: -16px;
        a {
          color: $theme-primary;
          border: 0;
          border-radius: 0;
          background-color: $theme-bg-primary;
          display: inline-block;
          width: 100%;
          &:before {
            transition: all ease 500ms;
            font-family: 'FontAwesome';
            font-size: 32px;
            content: '\f0c1';
            color: #333;
            display: inline-block;
            padding: 0 10px;
            margin-right: 10px;
          }
          &:after {
            content: '';
            display: block;
            width: 100%;
            margin: 10px auto 0;
            border-top: 2px solid #CCC;
          }
          &:nth-last-of-type(1) {
            &:after {
              border-color: transparent;
            }
          }
          &:hover, &:active, &:focus {
            &:before {
              color: #FFF;
              background-color: $theme-primary;
            }
          }
        }
      }
    }
    .panel-footer {
      color: #FFF;
      border-radius: 10px;
      background-color: $theme-primary;
      i {
        width: 80px;
        height: 80px;
        padding: 20px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
      }
      h3 {
        margin-top: 10px;
      }
    }
  }
}